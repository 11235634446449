/* see https://reacttraining.com/react-router/web/guides/scroll-restoration
* and https://stackoverflow.com/a/54343182/1042398
*/
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}