import axios from "axios";
import _ from "lodash";

import {
   GET_ANIMALS,
   SELECT_ANIMAL,
   SELECT_TRIP,
   SELECT_IMAGE,
   LOGIN,
   ADMINTOOLSUPDATE,
   GET_RANDOM_PHOTOS,
   TOGGLE_HEADER_MENU,
   GET_RECENT_POSTS
} from "./types";

/* here we're using redux thunk to return a function instead of the usual object.
* Normally, redux only allows us to return an object ( with type / payload ) in our actions.
* But with redux thunk we can return a function, and then we must manually call redux's
* dispatch function to trigger our reducers. This is most useful when doing async actions
* like getting data from a remote API, where we have to use async await. So in this case
* we're returning an async function that requests our image list, and when complete we
* call redux's dispatch function manually.
*
* We're also using lodash's memoize function to remember the output
* of the async call. That way when we return to the alphabetical list in the future, we
* don't have to rerun the ajax request.
*/

export const getAnimals = () => dispatch => _getAnimals( dispatch );
const _getAnimals = _.memoize( async ( dispatch ) => {
   const AnimalList_Prelim = await axios.get("https://www.mikewillisphotography.com/ps/utilities/GetAnimalList.php");
   dispatch({
      type: GET_ANIMALS,
      payload: AnimalList_Prelim.data
   });
});

export const getRandomPhotos = () => dispatch => _getRandomPhotos( dispatch );
const _getRandomPhotos = _.memoize( async ( dispatch ) => {
   const PhotoList_Prelim = await axios.get("https://www.mikewillisphotography.com/ps/utilities/GetGalleryImages.php?animal=random");
   dispatch({
      type: GET_RANDOM_PHOTOS,
      payload: PhotoList_Prelim.data
   });
});

export const selectAnimal = (animal) => async ( dispatch,getState ) => {
   /* when we get the images we do a post instead of a get, so we can transmit the session ID.
   * if the user is an admin, hidden images will also be returned
   */
   const { sessionID } = getState().login;
   const headers = {'Content-Type': 'multipart/form-data' };
   let bodyFormData = new FormData();
   bodyFormData.set('sessionID', sessionID);
   const ImageList_Prelim = await axios.post( `https://www.mikewillisphotography.com/ps/utilities/GetGalleryImages.php?animal=${animal}`, bodyFormData, {headers} );

   dispatch({
      type: SELECT_ANIMAL,
      payload:  {
         name: animal,
         ImageList: ImageList_Prelim.data
      }
   });
};

export const selectTrip = (trip) => async ( dispatch,getState ) => {
   /* when we get the images we do a post instead of a get, so we can transmit the session ID.
   * if the user is an admin, hidden images will also be returned
   */
   const { sessionID } = getState().login;
   const headers = {'Content-Type': 'multipart/form-data' };
   let bodyFormData = new FormData();
   bodyFormData.set('sessionID', sessionID);
   const ImageList_Prelim = await axios.post( `https://www.mikewillisphotography.com/ps/utilities/GetGalleryImages.php?trip=${trip}`, bodyFormData, {headers} );

   dispatch({
      type: SELECT_TRIP,
      payload:  {
         name: trip,
         ImageList: ImageList_Prelim.data
      }
   });
};

export const login = (formValues,history) => async (dispatch) => {
   //console.log("history"); console.log(history);
   //console.log("formValues"); console.log(formValues);

   //const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

   const headers = {'Content-Type': 'multipart/form-data' };
   let bodyFormData = new FormData();
   bodyFormData.set('username', formValues.username);
   bodyFormData.set('password', formValues.password);
   const response = await axios.post( "https://www.mikewillisphotography.com/ps/utilities/login.php", bodyFormData, {headers} );

   dispatch({ type: LOGIN, payload: response.data });
   history.push("/animals/");
};

export const adminToolsUpdate = ( formValues ) => async ( dispatch ) => {
   //console.log("formValues"); console.log(formValues);

   const headers = {'Content-Type': 'multipart/form-data' };
   let bodyFormData = new FormData();

   _.forEach( formValues, function(element, index) {
      bodyFormData.set( index, element );
   });
   const response = await axios.post( "https://www.mikewillisphotography.com/ps/utilities/AdminToolsReceiver.php", bodyFormData, {headers} );

   dispatch({ type: ADMINTOOLSUPDATE, payload: response.data });
};

/* this memoized version fails to call dispatch when re-visiting a page.. I'm not sure why. As a result, the state
* does not get updated, and the list of thumbnails stays the same as whatever animal was viewed last.
*
* update: I'm pretty sure that's on purpose. The memoized version isn't just skipping the dispatch call, it's skipping
* the whole inner function, which is the point of memoize. Instead of calling that function, it returns the output from
* the prior call. In this case though we need it to really call the function, because we need the state updated.
* so I'll come up with another way to save the lists of photos using state.
*/
// export const selectAnimal = (animal) => ( dispatch ) => _selectAnimal( animal, dispatch );
// const _selectAnimal = _.memoize( async (animal, dispatch) => {
//    const ImageList_Prelim = await axios.get(`https://www.mikewillisphotography.com/ps/utilities/GetGalleryImages.php?animal=${animal}`);
//    dispatch({
//       type: SELECT_ANIMAL,
//       payload:  {
//          name: animal,
//          ImageList: ImageList_Prelim.data
//       }
//    });
// });

// export const selectAnimal = ( animal ) => (dispatch) => {
//    var list = _selectAnimal(animal,dispatch);
//    console.log(list);
// }
// const _selectAnimal = _.memoize( async(animal,dispatch)=>{
//    console.log("action: selecting animal '" + animal + "'");
//    const ImageList_Prelim = await axios.get(`https://www.mikewillisphotography.com/ps/utilities/GetGalleryImages.php?animal=${animal}`);
//    dispatch({
//       type: SELECT_ANIMAL,
//       payload:  {
//          name: animal,
//          ImageList: ImageList_Prelim.data
//       }
//    });
//
//    return ImageList_Prelim.data;
// });

/* this non-memoized version correctly calls dispatch..
*/
// export const selectAnimal = (animal) => async ( dispatch ) => {
//    const ImageList_Prelim = await axios.get(`https://www.mikewillisphotography.com/ps/utilities/GetGalleryImages.php?animal=${animal}`);
//
//    dispatch({
//       type: SELECT_ANIMAL,
//       payload:  {
//          name: animal,
//          ImageList: ImageList_Prelim.data
//       }
//    });
// };

// NON-MEMOIZED VERSIONS:

// export const getAnimals = () => async ( dispatch ) => {
//    const AnimalList_Prelim = await axios.get("https://www.mikewillisphotography.com/ps/utilities/GetAnimalList.php");
//    dispatch({
//       type: GET_ANIMALS,
//       payload: AnimalList_Prelim.data
//    });
// };

// export const selectAnimal = (animal) => async ( dispatch ) => {
//    const ImageList_Prelim = await axios.get(`https://www.mikewillisphotography.com/ps/utilities/GetGalleryImages.php?animal=${animal}`);
//
//    dispatch({
//       type: SELECT_ANIMAL,
//       payload:  {
//          name: animal,
//          ImageList: ImageList_Prelim.data
//       }
//    });
// };

export const selectImage = (id) => {
   return {
      type: SELECT_IMAGE,
      payload: id
   };
};

export const toggleHeaderMenu = ( menu, formerMenu ) => {
   return {
      type: TOGGLE_HEADER_MENU,
      payload: formerMenu ? (menu !== formerMenu ? menu : false) : menu
   };
};

export const getRecentPosts = () => dispatch => _getRecentPosts( dispatch );
const _getRecentPosts = _.memoize( async ( dispatch ) => {
   // 2020-11-05: switching to the built in RESTful API that I didn't know existed..
   const RecentPostsResponse = await axios.get( "https://www.mikewillisphotography.com/blog/wp-json/wp/v2/posts?context=embed" );
   console.log("RecentPostsResponse",RecentPostsResponse);
   dispatch({
      type: GET_RECENT_POSTS,
      payload: RecentPostsResponse.data
   });

   // 2020-11-05: old way
   // const RecentPostsResponse = await axios.get("https://www.mikewillisphotography.com/ps/utilities/GetRecentPosts.php");
   // dispatch({
   //    type: GET_RECENT_POSTS,
   //    payload: RecentPostsResponse.data
   // });
});