import { combineReducers } from "redux";
import { reducer as reduxFormReducer } from "redux-form";
import { Cookies } from 'react-cookie';

import {
   GET_ANIMALS,
   SELECT_ANIMAL,
   SELECT_TRIP,
   SELECT_IMAGE,
   LOGIN,
   ADMINTOOLSUPDATE,
   GET_RANDOM_PHOTOS,
   TOGGLE_HEADER_MENU,
   GET_RECENT_POSTS
} from "../actions/types";

const animalsReducer = (state=null,action) => {
   switch( action.type ) {
   case GET_ANIMALS:
      var AnimalList = [];
      for ( const Letter in action.payload ) {
         AnimalList.push(`${Letter}|${action.payload[Letter]}`);
      }
      return AnimalList;
   default:
      return state;
   }
};

const getRandomPhotosReducer = (state=null,action) => {
   switch( action.type ) {
   case GET_RANDOM_PHOTOS:
      var ImageList = [];
      for ( const Image in action.payload ) {
         ImageList.push(action.payload[Image]);
      }
      return {
         name: action.payload.name,
         ImageList: ImageList
      }
   default:
      return state;
   }
};

const animalReducer = (state=null, action) => {
   switch( action.type ) {
   case SELECT_ANIMAL:
      var ImageList = [];
      for ( const Image in action.payload.ImageList ) {
         ImageList.push(action.payload.ImageList[Image]);
      }
      return {
         name: action.payload.name,
         ImageList: ImageList
      }
   default:
      return state;
   }
}

const tripReducer = (state=null, action) => {
   switch( action.type ) {
   case SELECT_TRIP:
      var ImageList = [];
      for ( const Image in action.payload.ImageList ) {
         ImageList.push(action.payload.ImageList[Image]);
      }
      return {
         name: action.payload.name,
         ImageList: ImageList
      }
   default:
      return state;
   }
}

const imageReducer = (state=null, action) => {
   switch( action.type ) {
   case SELECT_IMAGE:
      return { ...state, imageID: action.payload };
   default:
      return state;
   }
}

const cookies = new Cookies();
const INITIAL_STATE = {
   isSignedIn: cookies.get("isSignedIn") || false,
   sessionID: cookies.get("sessionID") || null
};
const loginReducer = (state=INITIAL_STATE, action) => {
   switch( action.type ) {
   case LOGIN:
      //console.log("payload"); console.log(action.payload);
      cookies.set("isSignedIn", action.payload.status, { path: "/", maxAge: 43200 });
      cookies.set("sessionID", action.payload.sessionID, { path: "/", maxAge: 43200 });
      return { ...state, isSignedIn: action.payload.status, sessionID: action.payload.sessionID };
   default:
      return state;
   }
}

const adminToolsUpdateReducer = ( state=null, action ) => {
   switch( action.type ) {
   case ADMINTOOLSUPDATE:
      console.log("action.payload");console.log(action.payload);
      return true;
   default:
      return state;
   }
};

const headerMenuReducer = (state=false, action) => {
   switch( action.type ) {
   case TOGGLE_HEADER_MENU:
      return action.payload;
   default:
      return state;
   }
}

const recentPostsReducer = ( state=[], action ) => {
   switch( action.type ) {
   case GET_RECENT_POSTS:
      console.log("redux reducing recent posts"); console.log(action);
      return action.payload;
   default:
      return state;
   }
}

export default combineReducers({
   animals: animalsReducer,
   animal: animalReducer,
   trip: tripReducer,
   image: imageReducer,
   form: reduxFormReducer,
   login: loginReducer,
   adminToolsUpdate: adminToolsUpdateReducer,
   randomPhotos: getRandomPhotosReducer,
   headerMenu: headerMenuReducer,
   recentPosts: recentPostsReducer
});