import 'semantic-ui-less/semantic.less'
import "../styles/App.css";

import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from 'react-ga';

import ScrollToTop from "./OpenSource/ScrollToTop";
import { toggleHeaderMenu, getRecentPosts } from "../actions";

import Header from "./Header";
import Footer from "./Footer";
// import Home from "./Home";
// import AlphabetizedList from "./AlphabetizedList";
// import PhotoGallery from "./PhotoGallery";
// import Login from "./Login";
// import BlogPost from "./BlogPost";
// import BlogPosts from "./BlogPosts";
//const Home = React.lazy(() => import('./Home'));
const Home = React.lazy(() => import('./Home_Functional'));
const AlphabetizedList = React.lazy(() => import('./AlphabetizedList'));
//const PhotoGallery = React.lazy(() => import('./PhotoGallery'));
const PhotoGallery = React.lazy(() => import('./PhotoGallery_Functional'));
const Login = React.lazy(() => import('./Login'));
//const BlogPost = React.lazy(() => import('./BlogPost'));
const BlogPost = React.lazy(() => import('./BlogPost_Functional'));
const BlogPosts = React.lazy(() => import('./BlogPosts'));

ReactGA.initialize('UA-74064887-1');

class App extends React.Component {
   componentDidMount(){
      this.props.getRecentPosts();
   }

   render(){
      return (
         <div className="ui container" onClick={()=>{this.props.toggleHeaderMenu(false);}}>
            <div className="ui semgnets">
               <BrowserRouter>
                  <ScrollToTop />
                  <Header RecentPosts={this.props.recentPosts} />
                  <React.Suspense fallback={"Loading"}>
                     <Switch>
                        <Route path="/" exact render={(props)=> <Home {...props} RecentPosts={this.props.recentPosts} />}/>
                        <Route path="/login/" exact component={Login} />
                        <Route path="/animals/" exact component={AlphabetizedList} />
                        <Route path="/animals/:Animal" exact render={(props) => <PhotoGallery key={1} {...props} galleryType="Animal" />} />
                        <Route path="/trips/:trip" exact render={(props) => <PhotoGallery key={2} {...props} galleryType="Trip" />} />
                        <Route path="/posts/:id/:slug" exact render={(props) => <BlogPost {...props} RecentPosts={this.props.recentPosts} />} />
                        <Route path="/categories/:searchA/:searchB" exact render={(props) => <BlogPosts {...props} />} />
                        <Route path="/archives/:searchA/:searchB" exact render={(props) => <BlogPosts {...props} />} />
                     </Switch>
                  </React.Suspense>
                  <Footer />
               </BrowserRouter>
            </div>
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      headerMenu: state.headerMenu,
      recentPosts: state.recentPosts
   };
};

export default connect(mapStateToProps,{
   toggleHeaderMenu: toggleHeaderMenu,
   getRecentPosts: getRecentPosts
})(App);