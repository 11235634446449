import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import * as Sentry from '@sentry/browser';

import App from "./components/App";
import reducers from "./reducers";

Sentry.init({dsn: "https://b48c00f39aca450abc8e9ef5c9c0c515@o46362.ingest.sentry.io/5262301"});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
   reducers,
   composeEnhancers(applyMiddleware(thunk))
);


ReactDOM.render(
   <Provider store={store}>
      <App />
   </Provider>,
   document.querySelector("#root")
);