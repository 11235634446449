import "../styles/Header.css";

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Menu, Dropdown, Icon } from 'semantic-ui-react';

import { toggleHeaderMenu } from "../actions";

class Header extends React.Component {

   state = {
      mobileNavVisible: false
   };

   closeMobileNav = () => {
      if ( this.state.mobileNavVisible ) {
         this.setState({mobileNavVisible:false});
      }
   }

   renderMenu = (menu) => {
      let result;
      let links;

      switch( menu ) {
      case "galleries":
         links = [
            { to: "/animals/favorites", text: "My Favorites" },
            { to: "/animals/", text: "Alphabetized" },
            { to: "/animals/newest", text: "Newest" }
         ];
         result = links.map( link=>{
            return <Dropdown.Item key={link.to}><Link onClick={()=>this.closeMobileNav()} to={link.to}>{link.text}</Link></Dropdown.Item>
         });
         break;
      case "posts":
         result = this.props.RecentPosts.map( (post) => {
            const href = `/posts/${post.id}/${post.slug}/`;
            //console.log("mapping over post:",post);
            //return ( <li key={post.id}><a href={post.link}>{post.LinkText}</a></li> );
            return (
               <Dropdown.Item key={post.id}><Link onClick={()=>this.closeMobileNav()} to={href}>{post.LinkText}</Link></Dropdown.Item>
            )
         });
         break;
      case "trips":
         links = [
            { to: "/trips/Botswana_2022", text: "Botswana - 2022" },
            { to: "/trips/CostaRica_2020", text: "Costa Rica - 2020" },
            { to: "/trips/Botswana_2020", text: "Botswana - 2020" },
            { to: "/trips/Botswana_2019", text: "Botswana - 2019" },
            { to: "/trips/Botswana_2018", text: "Botswana - 2018" },
            { to: "/trips/Tanzania_2017", text: "Tanzania - 2017" },
            { to: "/trips/Brazil_2016", text: "Brazil - 2016" },
            { to: "/trips/Galapagos_2015", text: "Galapagos 2015" },
            { to: "/trips/Botswana_2015", text: "Botswana - 2015" },
            { to: "/trips/SouthAfrica_2015", text: "South Africa 2015" }
         ];
         result = links.map( link=>{
            return <Dropdown.Item key={link.to}><Link onClick={()=>this.closeMobileNav()} to={link.to}>{link.text}</Link></Dropdown.Item>
         });
         break;
      default:
         result = (<React.Fragment></React.Fragment>);
      }
      return result;
   };

   stopClickStuff = (event) => {
      event.stopPropagation();
      event.preventDefault();
   };

   render() {

      let mobileNavVisibleClass = this.state.mobileNavVisible ? "showMenu" : "";

      return (
         <div className="myHeader ui segment">
            <Menu text>
               <Menu.Item>
                  <Link to="/"><img src="https://www.mikewillisphotography.com/images/logos/MikeWillisPhotographyLogo.png" height="105" width="232" alt="Mike Willis Photography" /></Link>
               </Menu.Item>

               <Icon name="bars" size="large" onClick={()=>this.setState({mobileNavVisible:!this.state.mobileNavVisible})} />

               <Menu.Item className={`right ${mobileNavVisibleClass}`}>
                  <Dropdown simple item open={false} text='Galleries'>
                     <Dropdown.Menu>
                        {this.renderMenu("galleries")}
                     </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown simple item open={false} text='Recent Posts'>
                     <Dropdown.Menu>
                        {this.renderMenu("posts")}
                     </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown simple item open={false} text='Trips'>
                     <Dropdown.Menu>
                        {this.renderMenu("trips")}
                     </Dropdown.Menu>
                  </Dropdown>
                  <Menu.Item>
                     <a href="/contact.php">Contact</a>
                  </Menu.Item>
                  <Menu.Item>
                     <Link to={`/posts/5/about-me/`}>About Me</Link>
                  </Menu.Item>
               </Menu.Item>

            </Menu>
         </div>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      headerMenu: state.headerMenu
   };
};

export default connect(mapStateToProps, {
   toggleHeaderMenu: toggleHeaderMenu
})(Header);