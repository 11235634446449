import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Footer extends React.Component {
   renderAdminLinks(){
      return (
         <div>
            <Link to="/login/">Gallery Admin Login</Link>
         </div>
      );
   }

   render(){
      let year = new Date().getFullYear();
      return (
         <React.Fragment>
            <br style={{clear: "both"}} />
            <div className="footer">
               <div className="container">
                  <p>&copy; 2015-{year} Michael Willis. All rights reserved.</p>
                  {!this.props.login.isSignedIn && this.renderAdminLinks()}
               </div>
            </div>
         </React.Fragment>
      );
   }
};

const mapStateToProps = (state) => {
   return {
      login: state.login
   }
};

export default connect(mapStateToProps)(Footer);